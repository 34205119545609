import { Injectable } from '@angular/core';
import { DataService } from '@yaris/core/data.service';
import { firstValueFrom } from '@yaris/core/utils/valueFrom';
import { ConfigParams, CustomizationParamsToken } from 'src/app/core/domain';

export type CustomizationParamsHashMap = Record<CustomizationParamsToken, { Enabled: boolean; Value: string }>;

@Injectable({
  providedIn: 'root',
})
export class CustomizationParamsService {
  private configParams: ConfigParams[] = [];

  constructor(private dataService: DataService) {}

  getConfigParam(token: CustomizationParamsToken): { Enabled: boolean; Value: string } {
    return this.configParams?.find((c) => c.token === token)?.config || { Enabled: false, Value: '' };
  }

  getAllConfigs(): CustomizationParamsHashMap {
    return this.configParams.reduce((acc, c) => {
      if (!CustomizationParamsToken[c.token]) return acc;

      acc[c.token] = c.config;
      return acc;
    }, {} as CustomizationParamsHashMap);
  }

  async load() {
    this.configParams = await firstValueFrom(this.dataService.getConfigParamsByGroup('CustomizationParams'));
  }
}
