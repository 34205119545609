import { HttpClient } from '@angular/common/http';
import { IJobLog } from '../interfaces/joblog.interface';
import { AdminBaseService } from './admin-base.service';

export class AdminJobs extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async getJob(id: string): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/getJob?id=${id}`);
  }

  public async getJobs(identifier?: string, description?: string): Promise<IJobLog[]> {
    const params = {};
    if (identifier) params['identifier'] = identifier;
    if (description) params['description'] = description;
    return this.getAsync('/light/api/background-tasks/jobLogs', { params });
  }

  public async getLastExecuted(identifier?: string, description?: string): Promise<IJobLog> {
    const params = {};
    if (identifier) params['identifier'] = identifier;
    if (description) params['description'] = description;
    return this.getAsync('/light/api/background-tasks/lastExecuted', { params });
  }

  public async runBackgroundJob(identifier?: string, description?: string, params?: any): Promise<IJobLog> {
    return this.postAsync('/light/api/background-tasks/runJob', { identifier, description, params });
  }

  public async deleteJobLogs(): Promise<any> {
    return this.deleteAsync('/light/api/background-tasks/jobLogs');
  }
}
