import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AsyncRequester } from '../interfaces/async-requester.interface';

interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export class AdminBaseService implements AsyncRequester {
  constructor(public http: HttpClient) {}

  public getAsync<T>(url: string, options?: HttpOptions): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(url, options).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  public deleteAsync<T>(url: string): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.delete<any>(url).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  public postAsync<T>(url: string, payload: any): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(url, payload).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }

  public putAsync<T>(url: string, payload: any): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.put<any>(url, payload).subscribe({
        next: (data) => resolve(data),
        error: (err) => reject(err),
      });
    });
  }
}
