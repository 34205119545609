import { Observable } from 'rxjs';

export const firstValueFrom = <T extends unknown>(observer: Observable<T>): Promise<T> => {
  return new Promise((resolve, reject) => {
    observer.subscribe({
      next: resolve,
      error: reject,
    });
  });
};
