<div class="lostConnection" *ngIf="getState() == 1 || sessionExpired">
  {{ 'HEADER.LOST_CONN' | translate }} {{ getTimeLost() }} {{ 'HEADER.SECONDS' | translate }}
</div>
<div
  class="lostConnection lostConnection-yellow"
  *ngIf="getState() == 2 && !sessionExpired"
  [innerHtml]="'HEADER.LOST_CONN_WARN' | translate"
></div>

<div class="container" fxLayout fxLayoutAlign="space-between center">
  <a
    href="#"
    id="logoURL"
    target="_blank"
    fxLayout
    fxLayoutAlign="start end"
    fxLayoutGap="1rem"
    fxFlex="100%"
    class="stakeholders"
    rel="noopener noreferrer"
  >
    <div class="sponsors" fxLayout fxLayoutGap=".5rem" *ngIf="customizationParams?.OrgLogo?.Enabled">
      <ng-container *ngIf="!customizationParams?.OrgLogo?.Value">
        <img src="assets/images/cedeao.png" alt="CEDEAO" />
        <img src="assets/images/ecas.png" alt="ECAS" />
        <img src="assets/images/icc.png" alt="ICC" />
        <img src="assets/images/cresmao.png" alt="CRESMAO" />
        <img src="assets/images/cresma.png" alt="CRESMA" />
      </ng-container>
      <ng-container *ngIf="customizationParams?.OrgLogo?.Value">
        <img [src]="customizationParams?.OrgLogo?.Value" [alt]="customizationParams?.AppName?.Value" />
      </ng-container>
    </div>
    <div
      class="logo-container"
      [class.no-logo]="!customizationParams?.MainLogo?.Enabled"
      fxLayout="column"
      fxLayoutGap=".125rem"
    >
      <img
        [src]="customizationParams?.MainLogo?.Value"
        [alt]="customizationParams?.AppName?.Value"
        class="logo"
        *ngIf="customizationParams?.MainLogo?.Enabled"
      />
      <div class="subtitle-container">
        <span class="{{ environment }}-subtitle">{{ getEnvironmentLongName(environment) }}</span>
        <span *ngIf="isLight" class="light-subtitle">LIGHT</span>
      </div>
    </div>

    <span class="app-title">{{ app }}</span>
    <div class="appVersion">{{ 'HEADER.VERSION' | translate }}: {{ appVersion }}</div>
  </a>
  <div
    class="situation"
    fxLayout
    fxLayoutGap=".5rem"
    fxLayoutAlign="center center"
    [ngClass]="titleColor"
    fxFlex="100%"
  >
    <i [inlineSVG]="titleIcon"></i>
    <span
      >{{ title }} <span class="subtitle">{{ subtitle }}</span></span
    >
  </div>

  <ul fxLayout fxLayoutAlign="end center" fxLayoutGap="2rem" fxFlex="100%" class="tools">
    <li class="tool user" *ngIf="showUserMenu()" (click)="userMenu = !userMenu">
      <span class="intro">{{ 'HEADER.SIGNEDINAS' | translate }}</span>
      <span class="username"
        >{{ user.Username }}<span class="expired" *ngIf="sessionExpired">{{ 'HEADER.EXPIRED' | translate }}</span></span
      >
      <ul class="nav-dropdown" fxLayout="column" *ngIf="userMenu" closeOnOutsideClick (close)="userMenu = false">
        <li *ngIf="sessionExpired" (click)="refreshSession()">{{ 'HEADER.REFRESHSESSION' | translate }}</li>
        <li (click)="onPreferencesClick()">{{ 'HEADER.PREFERENCES' | translate }}</li>
        <li *ngIf="canManageAdminBoard" (click)="onAdminBoardClick()">{{ 'HEADER.ADMINBOARD' | translate }}</li>
        <li (click)="logout()">Log Out</li>
      </ul>
    </li>
    <li
      fxLayout
      fxLayoutAlign="center center"
      fxLayoutGap=".5rem"
      class="tool language"
      inlineSVG="/assets/images/down-arrow.svg"
      [replaceContents]="false"
      (click)="langMenu = !langMenu"
    >
      <span>{{ 'LANGUAGE.' + language | uppercase | translate }}</span>
      <ul class="nav-dropdown" fxLayout="column" *ngIf="langMenu" closeOnOutsideClick (close)="langMenu = false">
        <li fxLayout fxLayoutAlign="start" *ngFor="let lang of languageOptions" (click)="onChangeLanguageClick(lang)">
          {{ 'LANGUAGE.' + lang | uppercase | translate }}
        </li>
      </ul>
    </li>
    <li class="tool" inlineSVG="/assets/images/home.svg" (click)="goHome()"></li>
    <li
      class="tool"
      *ngIf="showReport()"
      inlineSVG="/assets/images/list-attachments.svg"
      title="{{ 'REPORTS.PLACEHOLDERS.MODALTITLE' | translate }}"
      (click)="onListAttachmentsPDF()"
    ></li>
    <li
      class="tool"
      *ngIf="showCenterStatus()"
      inlineSVG="/assets/images/interregional.svg"
      title="{{ 'BUTTON.CENTERSTATUS' | translate }}"
      (click)="onCenterStatusClick()"
    ></li>
    <li
      class="tool"
      *ngIf="showNotifications()"
      inlineSVG="/assets/images/notification.svg"
      [replaceContents]="false"
      title="{{ 'BUTTON.NOTIFICATION' | translate }}"
      (click)="onNotificationClick()"
    >
      <span class="counter" *ngIf="newNotifications">!</span>
    </li>
  </ul>
  <app-notification-box *ngIf="notifications" (close)="onNotificationClose()"></app-notification-box>
</div>
<message-control class="alertBox" *ngIf="showMessageControl()"></message-control>
<error-notification></error-notification>
