import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { circle } from '@turf/turf';
import { DataService } from '@yaris/core/data.service';
import { CoordinatesFormat, EventCategory, IncidentStatus, Layer } from '@yaris/core/domain';
import { ModalService } from '@yaris/core/modal.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import { CoordinateService } from '@yaris/msa/mapbox/services/coordinate.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-msa-edit-incident',
  templateUrl: './msa-edit-incident.component.html',
  styleUrls: ['./msa-edit-incident.component.sass'],
})
export class MsaEditIncidentComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  layer: Layer;
  incident;
  goToManageIncidentsAfterSubmit: boolean = false;
  date: Date = new Date();
  layers: Layer[];
  coordinates: { type: string; coordinates: [] }[];
  type: string;
  formError = '';
  geojson;
  radius: number = 0;
  rangeCircle;
  createIncidentWarning: boolean = false;
  errorcontrolObj = {
    'objectModel.DMS.Longitude.Deg': '',
    'objectModel.DMS.Longitude.Min': '',
    'objectModel.DMS.Longitude.Sec': '',
    'objectModel.DMS.Latitude.Deg': '',
    'objectModel.DMS.Latitude.Min': '',
    'objectModel.DMS.Latitude.Sec': '',
    'objectModel.DMm.Longitude.Deg': '',
    'objectModel.DMm.Longitude.Min': '',
    'objectModel.DMm.Latitude.Deg': '',
    'objectModel.DMm.Latitude.Min': '',
    'objectModel.Ddd.Longitude.Deg': '',
    'objectModel.Ddd.Latitude.Deg': '',
    'objectModel.index': '',
  };

  private readonly hiddenProperties = [
    'Name',
    'PositionUtc',
    'COG',
    'SOG',
    'Provider',
    'UUID',
    'VesselType',
    'Flag',
    'PortOfOrigin',
    'Destination',
    'PortOfRegistry',
    'Cargo',
    'Classification',
    'IMONumber',
    'CallSign',
    'MMSI',
    'PortOfOriginATD',
    'DestinationETA',
    'VOI',
    'Owner',
    'Comments',
    'ShipType',
    'Destination',
    'DestinationETA',
    'WarningMessage',
    'IncidentType',
    'IsIncidentWarning',
    'Description',
    'Status',
    'Tags',
    'EventCategory',
  ];

  subscribedToFormChanges = false;
  longitudeHemisphereOptions: { label: string; value: 'E' | 'W' }[] = [];
  latitudeHemisphereOptions: { label: string; value: 'N' | 'S' }[] = [];
  pointhelper: number = 0;
  coordinatesFormatOptions: { label: string; value: CoordinatesFormat }[] = [];
  incidentTypeOptions: { label: string; value: string }[] = [];
  statusOptions: { label: string; value: string }[] = [];
  iconOptions: { label: string; value: string }[] = [];
  tagOptions: { label: string; value: string }[] = [];
  objectModel: {
    Name: string;
    Color: string;
    Icon: { label: string; value: string };
    Status: { label: string; value: string };
    Description: string;
    WarningMessage: string;
    IncidentType: { label: string; value: string };
    Tags: { label: string; value: string }[];
    Properties: { key: string; value: string }[];
    CreatedAt: Date;
    UpdatedAt: Date;
    Center: [number, number];
    Radius: number;
    Opacity: number;
    LineOpacity: number;
    LineColor: string;
    LineDashed: boolean;
    LineWidth: number;
    DMS: {
      Longitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    DMm: {
      Longitude: {
        Deg: number;
        Min: number;
      };
      Latitude: {
        Deg: number;
        Min: number;
      };
    };
    Ddd: {
      Longitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    CoordinatesFormat: { label: string; value: CoordinatesFormat };
  } = {
    Name: undefined,
    Color: undefined,
    Icon: undefined,
    Status: { label: undefined, value: undefined },
    Description: undefined,
    WarningMessage: undefined,
    IncidentType: { label: undefined, value: undefined },
    Properties: [],
    CreatedAt: undefined,
    UpdatedAt: undefined,
    Tags: undefined,
    Center: undefined,
    Radius: undefined,
    Opacity: 100,
    LineOpacity: 100,
    LineColor: undefined,
    LineDashed: false,
    LineWidth: 1,
    DMS: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
    },
    DMm: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
      },
    },
    Ddd: {
      Longitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
    },
    CoordinatesFormat: { label: undefined, value: undefined },
  };

  private situationId: string;
  private ngUnsubscribe = new Subject<void>();
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private translateService: TranslateService,
    private permissionsService: PermissionsService,
    private coordinateService: CoordinateService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    const layer = this.injector.get('layer') as Layer;
    this.incident = this.injector.get('incident');
    this.objectModel.Radius = this.incident.Geometry?.Radius;
    this.objectModel.Center = this.incident.Geometry?.Center;
    this.objectModel.Name = this.incident.Properties.Name;
    this.objectModel.Description = this.incident.Properties.Description;
    this.objectModel.Color = this.incident.Color;
    this.objectModel.Opacity = this.incident.Opacity ? this.incident.Opacity * 100 : 100;
    this.objectModel.LineColor = this.incident.LineColor;
    this.objectModel.LineOpacity = this.incident.LineOpacity ? this.incident.LineOpacity * 100 : 100;
    this.objectModel.LineWidth = this.incident.LineWidth ? this.incident.LineWidth : 1;
    this.objectModel.LineDashed = !!this.incident.LineDashed;
    this.objectModel.WarningMessage = this.incident.Properties.WarningMessage;
    this.objectModel.Status = {
      label: Object.keys(IncidentStatus).find((status) => status === this.incident.Properties.Status),
      value: this.incident.Properties.Status,
    };
    this.type = this.injector.get('type');
    this.goToManageIncidentsAfterSubmit = this.injector.get('goToManageIncidentsAfterSubmit');
    this.geojson = [[{ type: 'Point', coordinates: this.objectModel.Center }]];

    this.longitudeHemisphereOptions = [
      { label: 'E', value: 'E' },
      { label: 'W', value: 'W' },
    ];

    this.latitudeHemisphereOptions = [
      { label: 'N', value: 'N' },
      { label: 'S', value: 'S' },
    ];

    let [lngDMS, latDMS] = [undefined, undefined];
    let [lngDMm, latDMm] = [undefined, undefined];
    let [lngDdd, latDdd] = [undefined, undefined];

    if (this.incident.Properties.IsIncidentWarning) {
      [lngDMS, latDMS] = this.coordinateService.DDToDMS(this.incident.Geometry.Center);
      [lngDMm, latDMm] = this.coordinateService.DDToDMm(this.incident.Geometry.Center);
      [lngDdd, latDdd] = this.coordinateService.DDToDdd(this.incident.Geometry.Center);
    } else {
      [lngDMS, latDMS] = this.coordinateService.DDToDMS(this.incident.Geometry.Coordinates);
      [lngDMm, latDMm] = this.coordinateService.DDToDMm(this.incident.Geometry.Coordinates);
      [lngDdd, latDdd] = this.coordinateService.DDToDdd(this.incident.Geometry.Coordinates);
    }

    this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
    this.objectModel.DMS.Longitude.Min = lngDMS.min;
    this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
    this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDMS.hemisphere,
    );
    this.objectModel.DMS.Latitude.Deg = latDMS.deg;
    this.objectModel.DMS.Latitude.Min = latDMS.min;
    this.objectModel.DMS.Latitude.Sec = latDMS.sec;
    this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDMS.hemisphere,
    );
    this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
    this.objectModel.DMm.Longitude.Min = lngDMm.min;
    this.objectModel.DMm.Latitude.Deg = latDMm.deg;
    this.objectModel.DMm.Latitude.Min = latDMm.min;
    this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
    this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDdd.hemisphere,
    );
    this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
    this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDdd.hemisphere,
    );

    this.dataService
      .listMsaObjectsDefaultProperties('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((properties) => {
        this.incidentTypeOptions = properties
          .filter((prop) => prop.Type === 'IncidentType')
          .map((prop) => ({ label: prop.Value, value: prop.Value }));
        this.incidentTypeOptions.sort(this.compare);
        const incidentType = this.incidentTypeOptions.find(
          (type) => type.value === this.incident.Properties.IncidentType,
        );
        this.objectModel.IncidentType = { label: incidentType.label, value: incidentType.value };
      });

    this.dataService
      .listMsaIcons('true')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((icons) => {
        this.iconOptions = this.iconOptions.concat(icons.map((i) => ({ label: i.Name, value: i.IconURL })));
        this.objectModel.Icon = this.iconOptions.find((icon) => icon.value === this.incident.Icon);
        this.iconOptions.sort(this.compare);
      });

    this.dataService
      .listTags('Layer')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tags) => {
        this.tagOptions = tags.map((t) => ({ label: t.Name, value: t._id }));
        this.objectModel.Tags = this.tagOptions.filter((tag) => this.incident.Properties.Tags.includes(tag.label));
      });

    if (this.incident.Properties) {
      this.objectModel.Properties = Object.entries(this.incident.Properties)
        .filter(([k, v]) => !this.hiddenProperties.includes(k.replace(/\s/g, '')))
        .map(([k, v]) => ({ key: k as string, value: v as string }));
    }

    this.coordinatesFormatOptions = Object.keys(CoordinatesFormat).map((k) => ({
      label: this.translateService.instant('PREFERENCES.COORDINATES.' + k.toUpperCase()),
      value: CoordinatesFormat[k],
    }));

    this.statusOptions = Object.keys(IncidentStatus).map((k) => ({
      label: this.translateService.instant('ENUM.INCIDENTSTATUS.' + k.toUpperCase()),
      value: IncidentStatus[k],
    }));

    if (!this.incident.Properties.isIncidentWarning) {
      this.dataService
        .getDefaultParams()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((params) => {
          this.radius = params.IncidentRadius;
        });
    }

    this.objectModel.CoordinatesFormat = this.coordinatesFormatOptions.find(
      (o) => o.value === this.permissionsService.getUser().Preferences.CoordinatesFormat,
    );
    this.objectModel.CreatedAt = new Date(this.incident.CreatedAt);
    this.objectModel.UpdatedAt = new Date(this.incident.UpdatedAt);
  }

  compare(a, b) {
    if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    }
    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  transformPointToCoordinates() {
    const [lngDMS, latDMS] = this.coordinateService.DDToDMS(this.objectModel.Center);
    const [lngDMm, latDMm] = this.coordinateService.DDToDMm(this.objectModel.Center);
    const [lngDdd, latDdd] = this.coordinateService.DDToDdd(this.objectModel.Center);
    this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
    this.objectModel.DMS.Longitude.Min = lngDMS.min;
    this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
    this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDMS.hemisphere,
    );
    this.objectModel.DMS.Latitude.Deg = latDMS.deg;
    this.objectModel.DMS.Latitude.Min = latDMS.min;
    this.objectModel.DMS.Latitude.Sec = latDMS.sec;
    this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDMS.hemisphere,
    );
    this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
    this.objectModel.DMm.Longitude.Min = lngDMm.min;
    this.objectModel.DMm.Latitude.Deg = latDMm.deg;
    this.objectModel.DMm.Latitude.Min = latDMm.min;
    this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
    this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDdd.hemisphere,
    );
    this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
    this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDdd.hemisphere,
    );
  }

  transformCoordinatesToPoint() {
    let lng = undefined;
    let lat = undefined;
    if (this.objectModel.CoordinatesFormat.value == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere.value,
      );

      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere.value,
      );
    } else if (this.objectModel.CoordinatesFormat.value == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);

      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere.value,
      );

      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere.value,
      );
    }
    this.objectModel.Center = [lng, lat];
  }

  checkForInputErrors() {
    if (
      !this.subscribedToFormChanges &&
      this.form &&
      this.form.controls &&
      Object.keys(this.form.controls).length !== 0
    ) {
      this.listenToFormInputChanges();
      this.subscribedToFormChanges = true;
    }
  }

  onNewCustomFieldClick() {
    this.objectModel.Properties.push({
      key: undefined,
      value: undefined,
    });
  }

  onCloseCustomFieldClick(ix: number) {
    this.objectModel.Properties.splice(ix, 1);
  }

  listenToFormInputChanges() {
    const controlObj = {
      'objectModel.DMS.Latitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
      },
      'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Longitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
      },
      'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
      'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
      'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
      'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
      'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
    };

    Object.keys(controlObj).forEach((key) => {
      this.form.controls[key]?.valueChanges.subscribe((val) => {
        const controlObject = {
          'objectModel.DMS.Latitude.Deg': {
            Min: 0,
            Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
          },
          'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMS.Longitude.Deg': {
            Min: 0,
            Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
          },
          'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
          'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
          'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
          'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
          'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
          'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
          'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
        };
        if (isNaN(val)) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.NUMBERREQUIRED');
          this.form.controls[key].setErrors({ incorrect: true }); // <--- Set invalidNumber to true
        } else if (val < controlObject[key].Min) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMIN', {
            min: controlObject[key].Min,
          });
          this.form.controls[key].setErrors({ min: true });
        } else if (val > controlObject[key].Max) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMAX', {
            max: controlObject[key].Max,
          });
          this.form.controls[key].setErrors({ max: true });
        } else {
          this.form.controls[key].setErrors(null);
        }
      });
    });
  }

  get pointError(): string {
    return this.errorcontrolObj['objectModel.radius'];
  }
  get DMSLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Deg'];
  }
  get DMSLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Min'];
  }
  get DMSLatSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Sec'];
  }
  get DMSLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Deg'];
  }
  get DMSLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Min'];
  }
  get DMSLngSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Sec'];
  }
  get DMmLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Deg'];
  }
  get DMmLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Min'];
  }
  get DMmLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Deg'];
  }
  get DMmLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Min'];
  }
  get DddLatDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Latitude.Deg'];
  }
  get DddLngDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Longitude.Deg'];
  }
  get translatedType(): string {
    return this.translateService.instant('MSA.MSAOBJECT.CIRCLE');
  }

  private updateSourceFromRadius() {
    this.rangeCircle = circle(this.objectModel.Center as number[], this.radius, { units: 'nauticalmiles' });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    this.transformCoordinatesToPoint();

    let geometry;

    if (this.incident.Properties.IsIncidentWarning || this.createIncidentWarning) {
      this.updateSourceFromRadius();
      geometry = {
        Type: 'Polygon',
        Coordinates: this.rangeCircle.geometry.coordinates,
        Center: this.objectModel.Center,
        Radius: this.radius,
      };
    } else {
      geometry = {
        Type: 'Point',
        Coordinates: this.objectModel.Center,
      };
    }

    const propert: { key: string; value: string }[] = [];
    const properties = {
      ...this.objectModel.Properties.reduce((properties, pair) => {
        properties[pair.key] = pair.value;
        return properties;
      }, {}),
      Name: this.objectModel.Name,
      WarningMessage: this.objectModel.WarningMessage,
      IncidentType: this.objectModel.IncidentType.value,
      IsIncidentWarning: this.incident.Properties.IsIncidentWarning || this.createIncidentWarning,
      Description: this.objectModel.Description,
      Status: this.objectModel.Status.value,
      Tags: this.objectModel.Tags.map((tag) => tag.label),
      EventCategory: EventCategory.Incident,
      ...propert.reduce((properties, pair) => {
        properties[pair.key] = pair.value;
        return properties;
      }, {}),
    };

    this.dataService
      .updateEventLocation({
        ...this.incident,
        UserId: this.incident.UserId,
        Layer_id: this.incident.Layer_id,
        SituationId: this.incident.SituationId,
        CreatedAt: this.incident.CreatedAt,
        UpdatedAt: this.objectModel.UpdatedAt.toISOString(),
        Properties: properties,
        Geometry: geometry,
        Icon: this.objectModel.Icon.value,
        Color: this.objectModel.Color,
        Opacity: this.objectModel.Opacity / 100,
        LineColor: this.objectModel.LineColor,
        LineWidth: this.objectModel.LineWidth,
        LineDashed: this.objectModel.LineDashed,
        LineOpacity: this.objectModel.LineOpacity / 100,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (eventLocation) => this.injector.get('outputSubject').next(eventLocation),
        error: (err) => (this.formError = err.error.error.type),
      });
  }

  onCoordinatesFormatChange(changes) {
    this.objectModel.CoordinatesFormat = changes.newFormat;
    this.updateCoordinates(changes.oldFormat.value, changes.newFormat.value);
  }

  updateCoordinates(oldFormat, newFormat) {
    let lng = undefined;
    let lat = undefined;

    if (oldFormat == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere?.value,
      );
    } else if (oldFormat == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere?.value,
      );
    }

    if (newFormat == 'DMS') {
      const [lngDMS, latDMS] = this.coordinateService.DDToDMS([lng, lat]);
      this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
      this.objectModel.DMS.Longitude.Min = lngDMS.min;
      this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
      this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
        (o) => o.value === lngDMS.hemisphere,
      );
      this.objectModel.DMS.Latitude.Deg = latDMS.deg;
      this.objectModel.DMS.Latitude.Min = latDMS.min;
      this.objectModel.DMS.Latitude.Sec = latDMS.sec;
      this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
        (o) => o.value === latDMS.hemisphere,
      );
    } else if (newFormat == 'DM.m') {
      const [lngDMm, latDMm] = this.coordinateService.DDToDMm([lng, lat]);
      this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
      this.objectModel.DMm.Longitude.Min = lngDMm.min;
      this.objectModel.DMm.Latitude.Deg = latDMm.deg;
      this.objectModel.DMm.Latitude.Min = latDMm.min;
    } else {
      const [lngDdd, latDdd] = this.coordinateService.DDToDdd([lng, lat]);
      this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
      this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
        (o) => o.value === lngDdd.hemisphere,
      );
      this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
      this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
        (o) => o.value === latDdd.hemisphere,
      );
    }
  }

  isIncidentWarning(): boolean {
    return this.incident.Properties.IsIncidentWarning;
  }

  isIncidentWarningOrWillBe(): boolean {
    return this.isIncidentWarning() || this.createIncidentWarning;
  }

  canManageIncidentWarning(): boolean {
    return (
      this.permissionsService.getUserPermissions().MSA.Manage_Incident_Warning && this.permissionsService.isYaonde()
    );
  }

  onCreateIncidentWarningClick() {
    if (!this.incident.Properties.IsIncidentWarning && !this.objectModel.Status.value) {
      this.objectModel.Status = { label: IncidentStatus.Created, value: IncidentStatus.Created };
    }
  }
}
