<div class="access-denied" fxLayout="column" fxLayoutGap="2rem" fxLayoutAlign="center center">
  <img *ngIf="customizationParams?.MainLogo.Enabled" src="assets/images/logo.png" alt="YARIS" />
  <h1>{{ 'ACCESSDENIED.ACCESSDENIED' | translate }}</h1>
  <p>
    {{
      isNotAuthorizedForCurrentDomain()
        ? ('ACCESSDENIED.ROLEACCESSDENIED' | translate)
        : ('ACCESSDENIED.DESCRIPTION' | translate)
    }}
  </p>
  <main-button *ngIf="!isNotAuthorizedForCurrentDomain()" (click)="goBack()">{{
    'ACCESSDENIED.SIGNINWITHANOTHERACCOUNT' | translate
  }}</main-button>
  <main-button *ngIf="isNotAuthorizedForCurrentDomain()" (click)="changeDomainAccess()">{{
    'ACCESSDENIED.ACCESSMSADOMAIN' | translate
  }}</main-button>
</div>
